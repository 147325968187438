<template>
    <div class="main2">
        <canvas id="shareCanvas" ref="shareCanvas"></canvas>

        <!-- <van-image-preview v-model="showprievewImage" :images="images" :show-index="false"></van-image-preview> -->
        <!-- <img id="img" :src="imgUrl" alt="img" style="width:80%;" /> -->

        <div class="float-icon-box">
            <img v-if="canReserve" src="@/assets/img/live-zbyy.png" @click="liveReserve" class="float-icon " crossorigin="anonymous" />
            <img v-if="canVolunt" src="@/assets/img/live-fzky.png" @click.stop="showKYQrView" class="float-icon" crossorigin="anonymous" />
            <img v-if="liveDetail.id" src="@/assets/img/live-share.png" @click.stop="drawShare" class="float-icon " crossorigin="anonymous" />
            <img v-if="groupQrcode" src="@/assets/img/live-scan.jpg" @click.stop="showQroupQrcode" class="float-icon " crossorigin="anonymous" />
        </div>

        <!-- <img src="https://sho-static.shulan.com//minipro/202312271544096.png" class="img" crossorigin="anonymous" ref="banner" alt="banner" /> -->
        <!-- <img src="https://sho-static.shulan.com//minipro/202312271556318.png" crossorigin="anonymous" class="img" ref="code" alt="code" /> -->
        <iframe class="mobile-iframe" id="ifm" :src="liveUrl" style="width: 100%; height: 100%; overflow:hidden" scrolling="yes" allowFullScreen />
        <van-overlay :show="showprievewImage" @click="showprievewImage = false" z-index="1000">
            <div class="wrapper">
                <img id="img" :src="imgUrl" alt="img" @click.stop :style="{ width: overlayImgWidth + 'px', height: overlayImgHeight + 'px' }" />

                <div class="bottom-row-2">
                    长按海报保存到相册或者转发给好友
                </div>
            </div>
        </van-overlay>

        <van-overlay :show="showQrCode" @click="showQrCode = false" z-index="1000">
            <div class="wrapper">
                <div :style="{ background: '#Fff' }">
                    <img :src="yyQr" @click.stop :style="{ width: '300px', height: 'auto', display: 'block' }" />
                    <div class="bottom-row">
                        <div>免费复诊购药通道已开启</div>
                        长按保存二维码 支付宝扫一扫
                    </div>
                </div>

                <!-- <canvas id="qrcodeCanvas" class="qrcodeCanvas"></canvas> -->
            </div>
        </van-overlay>

        <van-overlay :show="isShowQroupQrcode" @click="isShowQroupQrcode = false" z-index="1000">
            <div class="wrapper">
                <div :style="{ background: '#Fff' }">
                    <img :src="groupQrcode" @click.stop :style="{ width: '300px', height: '300px', display: 'block' }" />
                    <div class="bottom-row">
                        <div>长按识别二维码，进入群聊</div>
                    </div>
                </div>

                <!-- <canvas id="qrcodeCanvas" class="qrcodeCanvas"></canvas> -->
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { Field, Button, Toast, Overlay } from 'vant';
import QRCode from 'qrcode';
import Vue from 'vue';
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Overlay);

// let IMG_BANNER = '';
// let IMG_CODE = '';
// let IMG_LOGO = '';
// let IMG_LOGO_W = 0;
// let IMG_LOGO_H = 0;
// let IMG_BANNER_WIDTH = 0;
// let IMG_BANNER_HEIGHT = 0;
// let scale = 1;
// let ratio = 0;
// let canvasDrawFontsInOneLine = 0;
// const titleFontSize = 18;
export default {
    components: {},
    data() {
        return {
            contactId: '',
            appid: '',
            pharmacyStoreCustomerId: '',
            pharmacyId: '',
            storeId: '',
            puserCode: '',
            liveId: '',
            liveDetail: {},
            storeInfo: null,
            // liveUrl: 'https://baidu.com'
            //liveUrl: 'https://sv.shulan.com/watch-new/11114407/vt?inset',
            imgUrl: '',
            showprievewImage: false,
            showQrCode: false,
            images: [],
            overlayImgWidth: 0,
            overlayImgHeight: 0,
            yyQr: null,
            isReserved: false,
            canReserve: false,
            canVolunt: false,
            cookieId: '',
            groupQrcode: '',
            isShowQroupQrcode: false
        };
    },

    computed: {
        liveUrl() {
            //如果有？，则加&，否则加？
            if (this.liveDetail.link.indexOf('?') > -1) {
                return this.liveDetail.link + '&parSid=' + this.cookieId + '&inset';
            } else {
                return this.liveDetail.link + '?parSid=' + this.cookieId + '&inset';
            }
        }
    },

    created() {
        console.log('route data', this.$route.query);
        this.contactId = this.$route.query.contactId || '';
        if (this.contactId == 'undefined') {
            this.contactId = '';
        }
        this.pharmacyStoreCustomerId = this.$route.query.pharmacyStoreCustomerId;
        this.puserCode = this.$route.query.puserCode;
        this.cookieId = this.$route.query.cookieId;
        this.pharmacyId = this.$route.query.pharmacyId;
        this.storeId = this.$route.query.storeId;

        // this.code = this.$route.query.code;

        this.liveId = this.$route.query.liveId;
    },
    mounted() {
        // const divElement = document.createElement('button');
        // divElement.textContent = '这是一个动态添加的div元素';
        // divElement.classList.add('share-btn'); // 设置class属性
        // divElement.addEventListener('click', this.drawShare1); // 添加点击事件监听器
        // document.body.appendChild(divElement);
        // this._calcData();

        this.getLiveDetail();
    },
    beforeDestroy() {
        clearInterval(this.setInterval);
        // if (this.visit != null) {
        //     this.watchEnd();
        // }
    },
    methods: {
        save() {
            this.showShareMenu(this.imgUrl);
        },
        showQroupQrcode() {
            this.isShowQroupQrcode = true;
        },
        liveReserve() {
            this.$dialog
                .alert({
                    message: '确认预约？',
                    confirmButtonText: '预约',
                    confirmButtonColor: '#18b09f',
                    showCancelButton: true
                })
                .then(() => {
                    this.$axios
                        .post(`/user/customer/contact/reverse`, {
                            contactId: this.contactId || '',
                            puserCode: this.puserCode,
                            pharmacyStoreCustomerId: this.pharmacyStoreCustomerId || '',
                            liveId: this.liveId,
                            pharmacyId: this.pharmacyId,
                            storeId: this.storeId
                        })
                        .then(res => {
                            //localstrage缓存已预约
                            // localStorage.setItem(`reserved_${this.contactId}`, 1);
                            this.canReserve = false;
                            Toast.success({
                                message: '直播已预约'
                            });
                        })
                        .catch(() => {
                            Toast.clear();
                        });
                });
        },
        getLiveDetail() {
            this.$axios
                .get(`/rightsInterest/live/activity/user/get/liveInfo?id=${this.liveId}`)
                .then(res => {
                    console.log('getLiveDetail', res);
                    this.liveDetail = res.data;
                    this.canVolunt = this.liveDetail.isVolunt;
                    if (this.liveDetail.isReserve) {
                        this.liveInterval();
                        this.setInterval = setInterval(() => {}, 1000 * 60);
                    }
                    this.groupQrcode = this.liveDetail.groupQrcode;
                })
                .catch(() => {
                    Toast.clear();
                });
        },

        liveInterval() {
            let { startTime, voluntStartTime, voluntEndTime, isVolunt, isReserve } = this.liveDetail;
            var now = new Date().getTime();

            // if (isVolunt) {
            //     voluntStartTime = new Date(voluntStartTime).getTime();
            //     voluntEndTime = new Date(voluntEndTime).getTime();
            //     if (now > voluntStartTime && now < voluntEndTime) {
            //         this.canVolunt = true;
            //     } else {
            //         this.canVolunt = false;
            //     }
            // }

            if (isReserve) {
                startTime = new Date(startTime.replace(/-/g, '/')).getTime();

                if (now > startTime) {
                    this.canReserve = false;
                } else {
                    this.$axios
                        .post(`/user/customer/contact/get/reverse/time`, {
                            contactId: this.contactId || '',
                            puserCode: this.puserCode,
                            pharmacyStoreCustomerId: this.pharmacyStoreCustomerId || '',
                            liveId: this.liveId,
                            pharmacyId: this.pharmacyId,
                            storeId: this.storeId
                        })
                        .then(res => {
                            debugger;
                            if (res.data) {
                                this.canReserve = false;
                            } else {
                                this.canReserve = true;
                            }
                        })
                        .catch(() => {});
                }
            }
        },

        getStoreInfo() {
            return new Promise((resolve, reject) => {
                if (this.storeInfo) {
                    resolve();
                    return;
                }
                this.$axios
                    .get(`/medicine/pharmacy/store/user/get/info?pharmacyId=${this.pharmacyId}&storeId=${this.storeId}`)
                    .then(res => {
                        this.storeInfo = res.data;

                        resolve();
                    })
                    .catch(() => {});
            });
        },

        checkIsShowScan() {
            this.$axios
                .get(`/activity/live/activity/cuser/one/byId?id=${this.liveId}`)
                .then(res => {
                    this.groupQrcode = (res.data || {}).groupQrcode || '';
                    resolve();
                })
                .catch(() => {});
        },

        drawShare() {
            if (this.imgUrl) {
                this.showprievewImage = true;
                return;
            }
            this.getStoreInfo().then(() => {
                Promise.all([this.loadCover(), this.loadLogo(), this.createQR(), this.loadGroupQrCode()]).then(([cover, logo, code, groupQrcode]) => {
                    this._drawShare2(cover, logo, code, groupQrcode);
                });
            });
            // this.loadLogo().then(image => {
            //     this._drawShare1(image);
            // });
        },
        // 绘制分享图
        _drawShare1(cover, logo, code) {
            // window.alert('drawShare1');
            // console.log('drawShare1');
            // return;
            var ratio = 1;

            const canvas = this.$refs.shareCanvas;
            const ctx = canvas.getContext('2d');

            const imgW = 750;
            var imgH = 0;
            if (cover) {
                imgH = (cover.height * imgW) / cover.width;
            }
            // debugger;

            const cw = 750;
            const ch = imgH + 417;

            let widthRatio = 300 / cw;
            let heightRatio = 500 / ch;
            let _ratio = Math.min(widthRatio, heightRatio);
            this.overlayImgWidth = cw * _ratio;
            this.overlayImgHeight = ch * _ratio;

            canvas.style.width = `${cw}px`;
            canvas.style.height = `${ch}px`;
            canvas.width = cw;
            canvas.height = ch;

            //封面
            if (cover) {
                var nowH = 0;
                ctx.clearRect(0, 0, cw * ratio, ch * ratio);
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, cw * ratio, ch * ratio);
                ctx.drawImage(cover, 0, 0, imgW, imgH);
            }

            var title = this.liveDetail.name;
            //标题
            if (title.length > 15) {
                title = title.substring(0, 15) + '...';
            }
            nowH += imgH + 36 + 50;
            ctx.fillStyle = '#272929';
            ctx.font = `${42 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
            ctx.fillText(
                title,
                46 * ratio,
                nowH
                // cw - 46 * 2
            );

            //分割线
            nowH += 50;
            ctx.moveTo(46, nowH); // 设置起点
            ctx.lineTo(658 + 46, nowH); // 绘制直线
            ctx.strokeStyle = '#CCCCCC'; // 设置描边颜色
            ctx.stroke();

            //logo

            if (logo) {
                var logX = 46;
                var logY = (ch - nowH - logo.IMG_LOGO_H) / 2 - 20;
                ctx.drawImage(logo, logX, logY + nowH, logo.IMG_LOGO_W, logo.IMG_LOGO_H);
            }

            if (code) {
                var logX = 540;
                var logY = (ch - nowH - 144) / 2 - 20;
                ctx.drawImage(code, logX, logY + nowH, 144, 144);

                ctx.fillStyle = '#272929';
                ctx.font = `${23 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
                ctx.fillText('微信扫码观看', logX, logY + 144 + 15 + nowH);
            }
            const src = canvas.toDataURL('image/jpg');
            console.log(src, 'src');
            this.imgUrl = src;
            // this.showShareMenu(src);

            this.showprievewImage = true;
            this.images = [src];
        },

        _drawShare2(cover, logo, code, groupQrcode) {
            // window.alert('drawShare1');
            // console.log('drawShare1');
            // return;
            var ratio = 1;

            const canvas = this.$refs.shareCanvas;
            const ctx = canvas.getContext('2d');

            const imgW = 750;
            var imgH = 0;
            if (cover) {
                imgH = 1012;
            }

            const cw = 750;
            const ch = 1012 + 612;

            let widthRatio = 300 / cw;
            let heightRatio = 500 / ch;
            let _ratio = Math.min(widthRatio, heightRatio);
            this.overlayImgWidth = cw * _ratio;
            this.overlayImgHeight = ch * _ratio;

            canvas.style.width = `${cw}px`;
            canvas.style.height = `${ch}px`;
            canvas.width = cw;
            canvas.height = ch;

            ctx.clearRect(0, 0, cw * ratio, ch * ratio);
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, cw * ratio, ch * ratio);
            // 渐变
            // var grd = ctx.createLinearGradient(0, 0, 0, 580);
            // grd.addColorStop(0, '#E8FCF7');
            // grd.addColorStop(1, 'white');

            // ctx.fillStyle = grd;
            // ctx.fillRect(0, 0, 750, 580);

            var nowH = 0;
            //封面
            if (cover) {
                ctx.drawImage(cover, 0, nowH, imgW, imgH);
                nowH += imgH + 20 + 40;
            }

            var title = this.liveDetail.name;
            //标题
            if (title.length > 15) {
                title = title.substring(0, 15) + '...';
            }

            ctx.fillStyle = '#1B1C21';
            ctx.font = `${44 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
            ctx.fillText(
                title,
                46 * ratio,
                nowH
                // cw - 46 * 2
            );
            // 介绍
            nowH += 60;
            ctx.font = `${34 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
            var intro = this.liveDetail.intro;
            // var intro = '大打算萨达所大萨达撒旦撒多少啊大声道撒旦撒大萨达萨达阿斯顿撒大萨达阿萨德';
            var intro1 = '';
            if (intro.length > 18) {
                intro1 = intro.substring(0, 18);
            } else {
                intro1 = intro;
            }
            ctx.fillText(
                intro1,
                46 * ratio,
                nowH
                // cw - 46 * 2
            );

            if (intro.length > 18) {
                var intro2 = '';
                if (intro.length > 36) {
                    intro2 = intro.substring(18, 35) + '...';
                } else {
                    intro2 = intro.substring(18, intro.length);
                }
                ctx.fillText(
                    intro2,
                    46 * ratio,
                    nowH + 45
                    // cw - 46 * 2
                );
            }
            // 直播时间
            nowH += 110;
            ctx.font = `${28 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
            ctx.fillText(
                '直播时间：',
                46 * ratio,
                nowH
                // cw - 46 * 2
            );
            nowH += 50;
            ctx.font = `${36 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
            ctx.fillText(
                this.parseLiveTime(),
                46 * ratio,
                nowH
                // cw - 46 * 2
            );
            nowH += 60;

            //分割线
            ctx.moveTo(46, nowH); // 设置起点
            ctx.lineTo(658 + 46, nowH); // 绘制直线
            ctx.strokeStyle = '#CCCCCC'; // 设置描边颜色
            ctx.stroke();

            //logo

            if (logo) {
                var logX = 46;
                var logY = (ch - nowH - logo.IMG_LOGO_H) / 2 - 20;
                ctx.drawImage(logo, logX, logY + nowH, logo.IMG_LOGO_W, logo.IMG_LOGO_H);
            }

            if (code) {
                var logX = 550 + 10;
                var logY = (ch - nowH - 188) / 2 - 20;
                ctx.drawImage(code, logX, logY + nowH, 164, 164);

                ctx.fillStyle = '#272929';
                ctx.font = `${23 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
                ctx.fillText('微信扫码观看', logX + 10, logY + 154 + 35 + nowH);
            }

            if (groupQrcode) {
                var logX = 370;
                var logY = (ch - nowH - 188) / 2 - 10;
                ctx.drawImage(groupQrcode, logX + 12, logY + nowH, 144, 144);

                ctx.fillStyle = '#272929';
                ctx.font = `${23 * ratio}px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
                ctx.fillText('扫码加入交流群', logX, logY + 144 + 35 + nowH);
            }

            const src = canvas.toDataURL('image/jpg');
            console.log(src, 'src');
            this.imgUrl = src;
            // this.showShareMenu(src);

            this.showprievewImage = true;
            // this.images = [src];
        },

        parseLiveTime() {
            let { startTime, endTime } = this.liveDetail;

            var startDate = new Date(startTime.replace(/-/g, '/'));
            var endDate = new Date(endTime.replace(/-/g, '/'));

            console.log('parseLiveTime', this.liveDetail, startDate, endDate);
            function formatNumber(number) {
                return number < 10 ? '0' + number : '' + number;
            }
            // return `${startTime}-${endTime}-${startDate}-${endDate}`;
            //判断是否是同一天
            if (startDate.toDateString() == endDate.toDateString()) {
                return `${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()} ${formatNumber(startDate.getHours())}:${formatNumber(
                    startDate.getMinutes()
                )} - ${formatNumber(endDate.getHours())}:${formatNumber(endDate.getMinutes())}`;
            } else {
                return `${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()} ${formatNumber(startDate.getHours())}:${formatNumber(
                    startDate.getMinutes()
                )} - ${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()} ${formatNumber(endDate.getHours())}:${formatNumber(endDate.getMinutes())}`;
            }
        },

        showKYQrView() {
            // var canvas = document.getElementById('qrcodeCanvas');
            // QRCode.toCanvas(canvas, 'sample text', function(error) {
            //     if (error) console.error(error);
            //     console.log('success!');
            // });
            // new QRCode(document.getElementById('qrcode'), 'http://jindo.dev.naver.com/collie');
            // this.showQrCode = true;

            if (this.yyQr == null) {
                this.getStoreInfo().then(() => {
                    this.createYuyueQR().then(image => {
                        this.yyQr = image;
                        this.showQrCode = true;
                    });
                });
            } else {
                this.showQrCode = true;
            }
        },

        showShareMenu(path) {
            wx.miniProgram.postMessage({
                data: path
            });
        },

        loadCover(url) {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.setAttribute('crossOrigin', 'anonymous');
                image.src = this.liveDetail.cover;
                image.onload = () => {
                    resolve(image);
                };

                image.onerror = () => {
                    resolve(null);
                };
            });
        },

        loadLogo() {
            return new Promise((resolve, reject) => {
                if (this.storeInfo.logo == null || this.storeInfo.logo == '') {
                    resolve(null);
                    return;
                }
                const image = new Image();
                var max_width = 320,
                    max_height = 180;
                image.setAttribute('crossOrigin', 'anonymous');
                image.src = this.storeInfo.logo;
                image.onload = () => {
                    // IMG_LOGO = image;
                    let widthRatio = max_width / image.width;
                    let heightRatio = max_height / image.height;
                    let ratio = Math.min(widthRatio, heightRatio);
                    image.IMG_LOGO_W = image.width * ratio;
                    image.IMG_LOGO_H = image.height * ratio;
                    resolve(image);
                };

                image.onerror = () => {
                    resolve(null);
                };
            });
        },
        loadGroupQrCode() {
            return new Promise((resolve, reject) => {
                if (this.groupQrcode == null || this.groupQrcode == '') {
                    resolve(null);
                    return;
                }
                const image = new Image();

                image.setAttribute('crossOrigin', 'anonymous');
                image.src = this.groupQrcode;
                image.onload = () => {
                    resolve(image);
                };

                image.onerror = () => {
                    resolve(null);
                };
            });
        },
        createQR() {
            //当前页面地址
            // var url = window.location.href;
            let url = '';
            if (process.env.NODE_ENV === 'production') {
                url = `https://sho-h5.shulan.com/#/commonJump/live?sourceChannel=2`;
                url += `&liveId=${this.liveId}&pharmacyId=${this.pharmacyId}&storeId=${this.storeId}&businessSourceFrom=${this.puserCode}&businessSourceType=3`;
            } else {
                let path = `/pages/activities/live/index.html?sourceChannel=2`;
                path += `&liveId=${this.liveId}&pharmacyId=${this.pharmacyId}&storeId=${this.storeId}&businessSourceFrom=${this.puserCode}&businessSourceType=3`;
                let data = encodeURIComponent(path);
                url = `https://open.weixin.qq.com/sns/getexpappinfo?appid=wx0c6e5ce6320e4be5&path=${data}`;
            }
            return new Promise((resolve, reject) => {
                QRCode.toDataURL(url, { errorCorrectionLevel: 'M' }, function(err, url) {
                    if (err) throw err;
                    const img = new Image();
                    img.src = url;
                    img.onload = function() {
                        resolve(img);
                    };

                    img.onerror = () => {
                        resolve(null);
                    };
                });
            });
        },
        createYuyueQR() {
            //当前页面地址
            let url = '';
            if (this.storeId && this.storeInfo.userCode) {
                //有门店id 直接去外配门店首页
                if (process.env.NODE_ENV === 'production') {
                    url = `https://sho-h5.shulan.com/#/commonJump/index?source=ali&type=pharmacyStore&resourceId=${this.liveId}&code=${this.storeInfo.userCode}`;
                } else {
                    url = `https://shoh5-lan.shulan.com:30443/#/commonJump/index?source=ali&type=pharmacyStore&resourceId=${this.liveId}&code=${this.storeInfo.userCode}`;
                }
            } else {
                if (process.env.NODE_ENV === 'production') {
                    url = `https://sho-h5.shulan.com/#/commonJump/SearchStore?resourceId=${this.liveId}&pharmacyId=${this.pharmacyId}`;
                } else {
                    url = `https://test-live.shulan.com/test-shoh5/#/commonJump/SearchStore?resourceId=${this.liveId}&pharmacyId=${this.pharmacyId}`;
                }
            }

            return new Promise((resolve, reject) => {
                QRCode.toDataURL(url, { errorCorrectionLevel: 'M', type: 'image/jpeg' }, function(err, url) {
                    if (err) throw err;
                    resolve(url);
                });
            });
        },
        calcDrawLogoWH(widht, height) {}
    }
};
</script>

<style lang="scss">
@function fit($num) {
    @return $num / 3;
}
img {
    -webkit-touch-callout: none;
}
#app {
    height: 100%;
}
.img {
    position: absolute;
    top: -999999px;
}
#shareCanvas {
    position: absolute;
    top: -9999px;
    display: block;
    margin: 0 auto;
    // width: 750px;
    // height: 1429px;
}
.main2 {
    // background: rgb(24, 24, 24);
    width: 100%;
    height: 100%;
}
.status-bar {
    background: #29a3dc;
    width: 100%;
    height: fit(80px);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: fit(30px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: fit(34px);
        height: fit(34px);
        margin-left: fit(8px);
    }
}

.status-bar-mobile {
    background: #12beae;
    width: 100%;
    height: 88px;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 34px;
        height: 34px;
        margin-left: 8px;
    }
}
.mobile-iframe {
    // pointer-events: none;
    // margin-top: 88px;
}

.fixed-btn {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 20px;
    z-index: 1000;
    color: #fff;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    font-family: Source Han Sans, Source Han Sans;
    font-weight: 500;

    img {
        width: 88px;
        height: 88px;
        margin-bottom: 10px;
    }
}

.share-btn {
    color: #10aeff;
    bottom: 200px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .bottom-row {
        // margin-top: 8px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 500;
        color: #1b1c21;
        .btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            img {
                width: 108px;
                height: 108px;
                margin-bottom: 24px;
            }
        }
    }

    .bottom-row-2 {
        margin-top: 22px;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 500;
        color: #1b1c21;
        height: 56px;
        border-radius: 28px;
        background: #fff;
    }
}

.float-icon-box {
    position: fixed;
    right: 20px;
    bottom: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.float-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    z-index: 10;
}

.qrcodeCanvas {
    width: 480px !important;
    height: 480px !important;
}
</style>
